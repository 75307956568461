import { FC } from 'react'
import styles from './BookingStepperMobile.module.scss'
import { StepId } from 'src/core/Shared/infrastructure/stepperManager'
import { ArrowIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowIcon'
import { IconText, Text } from 'src/ui/components'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'

interface Props {
  className?: string
  currentStep?: StepId
}

export const BookingStepperMobile: FC<Props> = ({ className, currentStep }) => {
  const { trans } = useTrans(['new-reservation'])

  const { navigate, queryUtils, back } = useApplicationRouter()

  const navigateToStep = async () => {
    /** Si el currentStep no está definido es porque estamos en la página de extras, porque no está en el flujo de pasos normal */
    if (isUndefined(currentStep)) {
      back()
    }

    if (currentStep === StepId.PERSONAL_DATA) {
      await navigate.toAvailabilityFromStepper()
    }

    if (currentStep === StepId.CHOOSE_PAYMENT) {
      await navigate.toPersonalDataFromStepper()
    }
  }

  const hasToShowInfo = isDefined(currentStep)

  return (
    <div className={className}>
      {currentStep !== StepId.AVAILABLE_ROOMS && (
        <button className={styles.backWrapper} onClick={navigateToStep}>
          <IconText
            className={styles.back}
            icon={ArrowIcon}
            iconSize="s"
            iconColor="button-secondary-enabled"
            text={trans('booking-stepper_mobile_back')}
            fontStyle="s-500"
            fontColor="primary"
            gap="xs"
          />
        </button>
      )}
      {hasToShowInfo && (
        <Text fontStyle="s-500" color="mid">
          {trans('booking-stepper_mobile_info', { step: currentStep })}
        </Text>
      )}
    </div>
  )
}
