import { FC, MouseEventHandler, ReactElement } from 'react'
import styles from './MobileDatePicker.module.scss'
import dayStyles from './Day.module.scss'
import weekStyles from './Week.module.scss'
import { Calendar, DateObject } from 'react-multi-date-picker'
import { classNames } from 'src/ui/utils/classnames'
import { weekStartDayIndex } from 'src/ui/components/molecules/DatePicker/weekStartDayIndex'
import { locales } from 'src/ui/components/molecules/DatePicker/locales'
import { useDatePicker } from '../DatePickerContext'
import { Day } from '../Day'

interface Props {
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  isOneDaySelected: boolean | undefined
  onDatesChange: (dates: DateObject[]) => void
  footer: ReactElement | ReactElement[]
}

export const MobileDatePicker: FC<Props> = ({
  isOneDaySelected,
  renderNav,
  onDatesChange,
  footer,
}) => {
  const { checkIn, checkOut, locale, isDayDisabled, minDate, maxDate, range } =
    useDatePicker()

  return (
    <div className={styles.container}>
      <Calendar
        className={classNames(
          checkIn && checkOut && dayStyles.selectedDays,
          isOneDaySelected && dayStyles.disableSelectSameDayTwice,
          styles.calendar,
          dayStyles.day,
          weekStyles.week,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={false}
        numberOfMonths={1}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          if (isDayDisabled(date.toDate())) {
            return {
              disabled: true,
              children: <Day date={date.toDate()} />,
            }
          }

          return {
            children: <Day date={date.toDate()} />,
          }
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
      />
    </div>
  )
}
