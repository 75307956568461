import { createContext, FC, ReactNode, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { isDayDisabled } from './isDayDisabled'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface DatePickerContext {
  checkIn: Date
  checkOut?: Date
  minDate: Date
  maxDate: Date
  locale: string
  isDayDisabled: (day: Date) => boolean
  range: Date[]
}

const defaultDatePickerContext: DatePickerContext = {
  checkIn: new Date(),
  checkOut: undefined,
  minDate: new Date(),
  maxDate: new Date(),
  locale: 'en',
  isDayDisabled: () => false,
  range: [],
}

export const DatePickerContext = createContext<DatePickerContext>(
  defaultDatePickerContext,
)

interface DatePickerProviderProps {
  children: ReactNode
}

/***
 * Este provider necesita estar dentro de un FormProvider para que pueda acceder a los valores del formulario
 */
export const DatePickerProvider: FC<DatePickerProviderProps> = ({
  children,
}) => {
  const { watch } = useFormContext<DatesFormValues>()
  const checkIn = watch('dates.checkIn')
  const checkOut = watch('dates.checkOut')

  const languageConfig = useLanguageConfig()
  const locale = languageConfig.properties.culture

  const maxDate = datesManager.getMaxDate()
  const minDate = datesManager.getMinDate()
  const range = isDefined(checkOut) ? [checkIn, checkOut] : [checkIn]

  return (
    <DatePickerContext.Provider
      value={{
        checkIn,
        checkOut,
        locale,
        isDayDisabled: day => isDayDisabled({ day, checkIn, checkOut }),
        minDate,
        maxDate,
        range,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  )
}

export const useDatePicker = () => useContext(DatePickerContext)
