import {
  ForwardRefRenderFunction,
  MouseEventHandler,
  ReactElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Calendar, DateObject } from 'react-multi-date-picker'
import styles from './DesktopDatePicker.module.scss'
import dayStyles from './Day.module.scss'
import weekStyles from './Week.module.scss'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { classNames } from 'src/ui/utils/classnames'
import { weekStartDayIndex } from 'src/ui/components/molecules/DatePicker/weekStartDayIndex'
import { locales } from 'src/ui/components/molecules/DatePicker/locales'
import { useDatePicker } from '../DatePickerContext'
import { Day } from '../Day'

interface Props {
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  isOneDaySelected: boolean | undefined
  onDatesChange: (dates: DateObject[]) => void
  footer: ReactElement | ReactElement[]
}

const DesktopDatePickerWithRef: ForwardRefRenderFunction<
  {
    set?: (key: string, value: number) => void
  },
  Props
> = ({ onDatesChange, isOneDaySelected, renderNav, footer }, ref) => {
  const { checkIn, checkOut, locale, isDayDisabled, minDate, maxDate, range } =
    useDatePicker()
  const [daySelected, setDaySelected] = useState<Date | undefined>()
  const [isNextDay, setIsNextDay] = useState<boolean | undefined>(false)
  const [isPreviousDay, setIsPreviousDay] = useState<boolean | undefined>(false)
  const calendarRef = useRef<{
    set?: (key: string, value: number) => void
  }>()

  useImperativeHandle(
    ref,
    () => {
      return {
        set: isDefined(calendarRef.current)
          ? calendarRef.current.set
          : undefined,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarRef.current],
  )

  const handleSelectDay = (date: Date) => {
    setDaySelected(date)
    setIsNextDay(false)
    setIsPreviousDay(false)
    return
  }

  const handleRangeOver = (date: Date) => {
    if (isDefined(daySelected) && isUndefined(checkOut)) {
      if (date > daySelected) {
        setIsNextDay(true)
        setIsPreviousDay(false)
        return
      }

      if (date < daySelected) {
        setIsNextDay(false)
        setIsPreviousDay(true)
        return
      }

      setIsNextDay(false)
      setIsPreviousDay(false)
      return
    }
  }

  return (
    <div className={styles.container}>
      <Calendar
        ref={calendarRef}
        className={classNames(
          checkIn && checkOut && dayStyles.selectedDays,
          isNextDay && dayStyles.nextDay,
          isPreviousDay && dayStyles.previousDay,
          isOneDaySelected && dayStyles.disableSelectSameDayTwice,
          dayStyles.day,
          weekStyles.week,
          styles.calendar,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={false}
        numberOfMonths={2}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          if (isDayDisabled(date.toDate())) {
            return {
              disabled: true,
              children: <Day date={date.toDate()} />,
            }
          }

          return {
            onClick: () => {
              handleSelectDay(date.toDate())
            },
            onMouseOver: () => {
              handleRangeOver(date.toDate())
            },
            children: <Day date={date.toDate()} />,
          }
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
      />
    </div>
  )
}

export const DesktopDatePicker = forwardRef(DesktopDatePickerWithRef)
