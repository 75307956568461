import {
  ForwardRefRenderFunction,
  MouseEventHandler,
  forwardRef,
  useState,
} from 'react'
import { Media } from 'src/ui/styles/objects/Media'
import { getTotalNights } from './getTotalNights'
import { DesktopDatePicker } from './DesktopDatePicker'
import { MobileDatePicker } from './MobileDatePicker'
import { Footer } from './Footer'
import { DateObject } from 'react-multi-date-picker'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import {
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { NavIcon } from 'src/ui/components/molecules/DatePicker/NavIcon'
import { useDatePicker } from './DatePickerContext'

interface Props {
  error?: {
    message?: string
  }
  onDatesChange: (checkIn: Date, checkOut?: Date) => void
}

const DatePickerWithRef: ForwardRefRenderFunction<
  {
    set?: (key: string, value: number) => void
  },
  Props
> = ({ error, onDatesChange }, ref) => {
  const [isOneDaySelected, setIsOneDaySelected] = useState<boolean | undefined>(
    false,
  )
  const languageConfig = useLanguageConfig()
  const { checkIn, checkOut } = useDatePicker()

  const isRTL = languageConfig.properties.isRTL
  const nights = getTotalNights(checkIn, checkOut)

  const renderNav = (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => {
    return (
      <NavIcon
        icon={getIcon(direction)}
        onClick={handleMonthChange}
        disabled={disabled}
      />
    )
  }

  const getIcon = (direction: 'left' | 'right') => {
    if (!isRTL) {
      return direction
    }

    return direction === 'right' ? 'left' : 'right'
  }

  const handleDatesChange = (selectedDates: DateObject[]) => {
    if (isUndefined(selectedDates) || isEmpty(selectedDates)) {
      return
    }

    onDatesChange(selectedDates[0]?.toDate(), selectedDates[1]?.toDate())
    setIsOneDaySelected(selectedDates?.length === 1)
  }

  const footer = (
    <Footer
      key="footer"
      position="bottom"
      errorMessage={error?.message}
      nights={nights}
    />
  )

  return (
    <>
      <div>NEW CALENDAR</div>
      <Media mobile tablet>
        <div>
          <MobileDatePicker
            isOneDaySelected={isOneDaySelected}
            onDatesChange={handleDatesChange}
            renderNav={renderNav}
            footer={footer}
          />
        </div>
      </Media>

      <Media laptop desktop>
        <div>
          <DesktopDatePicker
            ref={ref}
            isOneDaySelected={isOneDaySelected}
            onDatesChange={handleDatesChange}
            renderNav={renderNav}
            footer={footer}
          />
        </div>
      </Media>
    </>
  )
}

export const DatePicker = forwardRef(DatePickerWithRef)
