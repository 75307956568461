import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import styles from './Day.module.scss'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { useDatePicker } from '../DatePickerContext'

interface DayProps {
  date: Date
}

export const Day: FC<DayProps> = ({ date }) => {
  const currentDay = date.getDate()
  const { isDayDisabled } = useDatePicker()
  const ariaLabel = datesManager.formatDateToLocale(date, 'long', 'long')

  return (
    <Text
      fontStyle="s-700"
      color={isDayDisabled(date) ? 'disabled' : undefined}
      className={isDayDisabled(date) ? styles.disabledDate : undefined}
      ariaLabel={ariaLabel}
    >
      {currentDay}
    </Text>
  )
}
