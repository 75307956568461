import { ForwardRefRenderFunction, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { DatePicker } from 'src/ui/components/molecules/DatePickerV2'

const DatePickerFormWithRef: ForwardRefRenderFunction<{
  set?: (key: string, value: number) => void
}> = ({}, ref) => {
  const { setValue, clearErrors, formState, getFieldState } =
    useFormContext<DatesFormValues>()

  const error = getFieldState('dates', formState).error

  const handleDatesChange = (checkIn: Date, checkOut?: Date) => {
    clearErrors()
    setFormValues(checkIn, checkOut)
  }

  const setFormValues = (checkIn: Date, checkOut: Date | undefined) => {
    setValue('dates', {
      checkIn,
      checkOut,
    })
  }

  return (
    <DatePicker ref={ref} error={error} onDatesChange={handleDatesChange} />
  )
}

export const DatePickerForm = forwardRef(DatePickerFormWithRef)
